@import '../consts';

#instructions section#kanta,
#instructions section#kanta .container {
  padding-bottom: 0;
}

#instructions section#future,
#instructions section#future .container {
  padding-top: 0;
}
